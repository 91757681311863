video {
    width: 160px;
    height: 160px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    border-style: dashed;
    border-width: thick;
    padding: 50px;
}

.videoContainer {
    display: flex;
}

.buttons {
    display: flex;
}

.info {
    display: flex;
}
